<template lang="pug">
.mt-3
  template(v-if="automationModuleTemplate === 'after_flex_collect_create_or_update_flexible_power_api'")
    h2 {{ $t('automations.labels.source') }}
    .form-group
      label {{ $t('automations.labels.module_number') }}
      v-select(
        :options="moduleInputPositions"
        label="name"
        v-model="moduleInputPosition"
      )

  h2.mt-4 {{ $t('automations.labels.collection_sheets_settings') }}
  .form-group
    label {{ $t("automations.labels.collection_sheets_table_id") }}
    v-select.bg-white(
      :options="tables"
      label="name"
      :reduce="o => o.id"
      v-model="collectionSheetsTableId"
      @option:selected="loadCollectionSheetsFields"
    )
  template(v-if="collectionSheetsTableId && collectionSheetsFields.length")
    .form-group
      label
        | {{ $t("automations.labels.first_day_of_week_field_id") }}
      v-select.bg-white(
        :options="collectionSheetsFields"
        label="name"
        :reduce="o => o.id"
        v-model="firstWeekDayFieldId"
      )
    .form-group
      label
        | {{ $t("automations.labels.reference_to_site_field_id") }}
      v-select.bg-white(
        :options="collectionSheetsFields"
        label="name"
        :reduce="o => o.id"
        v-model="referenceToSiteFieldId"
      )
    .form-group
      label
        | {{ $t("automations.labels.default_period_field_id") }}
      v-select.bg-white(
        :options="collectionSheetsFields"
        label="name"
        :reduce="o => o.id"
        v-model="defaultPeriodFieldId"
      )
    .form-group
      label
        | {{ $t("automations.labels.source_id_field_id") }}
      v-select.bg-white(
        :options="collectionSheetsFields"
        label="name"
        :reduce="o => o.id"
        v-model="sourceIdFieldId"
      )
    .form-group
      label
        | {{ $t("automations.labels.unavailability_days_field_ids") }}
        small.ml-1 {{ $t("automations.labels.days_in_order_hint") }}
      v-select.bg-white(
        :options="collectionSheetsFields"
        :multiple="true"
        label="name"
        :reduce="o => o.id"
        :close-on-select="false"
        :clear-search-on-select="false"
        :clear-search-on-blur="() => true"
        v-model="selectedUnavailabilityDaysFieldIds"
      )
    .form-group
      label
        | {{ $t("automations.labels.activity_times_field_ids") }}
        small.ml-1 {{ $t("automations.labels.days_in_order_hint") }}
      v-select.bg-white(
        :options="collectionSheetsFields"
        :multiple="true"
        label="name"
        :reduce="o => o.id"
        :close-on-select="false"
        :clear-search-on-select="false"
        :clear-search-on-blur="() => true"
        v-model="selectedActivityTimesFieldIds"
      )

  h2.mt-4 {{ $t('automations.labels.unavailabilities_settings') }}
  .form-group
    label {{ $t("automations.labels.unavailability_table_id") }}
    v-select.bg-white(
      :options="tables"
      label="name"
      :reduce="o => o.id"
      v-model="unavailabilityTableId"
      @option:selected="loadUnavailabilityFields"
    )
  template(v-if="unavailabilityTableId && unavailabilityFields.length")
    .form-group(v-for="fieldParamName in unavailabilityNeededFields")
      label {{ $t('automations.labels.fields') }} {{ fieldParamName }}
      v-select.bg-white(
        :options="unavailabilityFields"
        :reduce="o => o.id"
        label="name"
        v-model="selectedUnavailabilityFields[fieldParamName]"
      )
  .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
    button.btn.btn-primary(
      type="button"
      :disabled="!formIsValid"
      @click.stop="updateAutomationModule"
    ) {{ $t('save') }}

</template>

<script>
import { api } from '../../../../api/client';
import Field from '../../../../models/field';
import Table from '../../../../models/table';

export default {
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    automationModuleTemplate: {
      type: String,
      required: true,
    },
    configParams: {
      type: Object,
      required: true,
    },
    automationModulePosition: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      selectedUnavailabilityDaysFieldIds: [],
      selectedActivityTimesFieldIds: [],
      tables: [],
      collectionSheetsTableId: null,
      collectionSheetsFields: [],
      unavailabilityTableId: null,
      unavailabilityFields: [],
      selectedUnavailabilityFields: {},
      firstWeekDayFieldId: null,
      referenceToSiteFieldId: null,
      defaultPeriodFieldId: null,
      sourceIdFieldId: null,
      unavailabilityNeededFields: [
        'identifier',
        'range',
        'site'
      ],
      moduleInputPositions: [],
      moduleInputPosition: null,
    }
  },
  created() {
    this.moduleInputPosition = this.configParams.module_input_position || 1;
    this.moduleInputPositions = Array.from({ length: this.automationModulePosition - 1 }, (_, i) => i + 1);
    api.tables.index().then(this.buildTables);
    this.unavailabilityNeededFields.forEach((field) => this.$set(this.selectedUnavailabilityFields, field, null))
    if (this.configParams) { // prop
      this.collectionSheetsTableId = this.configParams.collection_sheets_table_id;
      this.firstWeekDayFieldId = this.configParams.first_week_day_field_id;
      this.referenceToSiteFieldId = this.configParams.reference_to_site_field_id;
      this.defaultPeriodFieldId = this.configParams.default_period_field_id;
      this.selectedUnavailabilityDaysFieldIds = this.configParams.unavailability_days_field_ids || [];
      this.selectedActivityTimesFieldIds = this.configParams.activity_times_field_ids || [];
      this.unavailabilityTableId = this.configParams.unavailability_table_id;
      if (this.configParams.unavailability_table) {
        this.selectedUnavailabilityFields.identifier = this.configParams.unavailability_table.identifier_field_id;
        this.selectedUnavailabilityFields.range = this.configParams.unavailability_table.range_field_id;
        this.selectedUnavailabilityFields.site = this.configParams.unavailability_table.site_field_id;
      }
      this.sourceIdFieldId = this.configParams.source_id_field_id;
    }
    if (this.configParams.collection_sheets_table_id) {
      api.fields.index({ tableId: this.configParams.collection_sheets_table_id }).then(this.buildFields("collectionSheetsFields"));
    }
    if (this.configParams.unavailability_table_id) {
      this.loadUnavailabilityFields({ id: this.configParams.unavailability_table_id });
    }
  },
  computed: {
    form() {
      return {
        automationModuleId: this.automationModuleId,
        configParams: {
          module_input_position: this.moduleInputPosition,
          collection_sheets_table_id: this.collectionSheetsTableId,
          first_week_day_field_id: this.firstWeekDayFieldId,
          reference_to_site_field_id: this.referenceToSiteFieldId,
          default_period_field_id: this.defaultPeriodFieldId,
          unavailability_days_field_ids: this.selectedUnavailabilityDaysFieldIds,
          activity_times_field_ids: this.selectedActivityTimesFieldIds,
          unavailability_table_id: this.unavailabilityTableId,
          source_id_field_id: this.sourceIdFieldId,
          unavailability_table: {
            identifier_field_id: this.selectedUnavailabilityFields.identifier,
            range_field_id: this.selectedUnavailabilityFields.range,
            site_field_id: this.selectedUnavailabilityFields.site,
          }
        }
      }
    },
    formIsValid() {
      const { configParams } = this.form
      return !!(
        this.form.automationModuleId &&
        (configParams.module_input_position || automationModuleTemplate !== "after_flex_collect_create_or_update_flexible_power_api") &&
        configParams.collection_sheets_table_id &&
        configParams.first_week_day_field_id &&
        configParams.reference_to_site_field_id &&
        configParams.default_period_field_id &&
        configParams.unavailability_days_field_ids.length >= 5 &&
        configParams.activity_times_field_ids.length === 14 && // begin + end per day
        configParams.unavailability_table_id &&
        configParams.unavailability_table.identifier_field_id &&
        configParams.unavailability_table.range_field_id &&
        configParams.unavailability_table.site_field_id &&
        configParams.source_id_field_id
      )
    }
  },
  methods: {
    buildFields(target = "fields") {
      return ({ data: fieldsData }) => {
        this[target] = Field.build(fieldsData);
      }
    },
    buildTables({ data: tablesData }) {
      this.tables = Table.build(tablesData);
    },
    loadCollectionSheetsFields({ id: tableId }) {
      api.fields.index({ tableId }).then(this.buildFields("collectionSheetsFields"))
    },
    loadUnavailabilityFields({ id: tableId }) {
      api.fields.index({ tableId }).then(this.buildFields("unavailabilityFields"))
    },
    updateAutomationModule() {
      if (this.formIsValid) {
        api.automationModules.update(this.form)
      }
    }
  }
}
</script>
