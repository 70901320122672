import '../utils/time_and_memory_monitor';

import Vue   from "vue/dist/vue.esm";
import store from "./store/store";
import i18n  from "./locales/locales.js";

import { truncate } from "lodash";
import moment       from "moment";

import "./directives.js";

// COMPONENTS
import ProjectShow from "./components/ProjectShow.vue";
import tableProfileFilters from "./components/standalones/tableProfileFilters.vue";
import deleteAutomationModule from "./components/standalones/automations/deleteAutomationModule.vue";
import whenRecordMatchesConditions from "./components/standalones/automations/triggers/whenRecordMatchesConditions.vue";
import whenRecordCreated from "./components/standalones/automations/triggers/whenRecordCreated.vue";
import whenRecordUpdated from "./components/standalones/automations/triggers/whenRecordUpdated.vue";
import whenRecordDestroyed from "./components/standalones/automations/triggers/whenRecordDestroyed.vue";
import atScheduledTime from "./components/standalones/automations/triggers/atScheduledTime.vue";
import whenCalled from "./components/standalones/automations/triggers/whenCalled.vue";
import sendNotifications from "./components/standalones/automations/actions/sendNotifications.vue";
import exportRecord from "./components/standalones/automations/actions/exportRecord.vue";
import sendEmails from "./components/standalones/automations/actions/sendEmails.vue";
import CreateOrUpdateFlexUnavailability from "./components/standalones/automations/actions/CreateOrUpdateFlexUnavailability.vue";
import PushDataToFlexiblePowerAPI from "./components/standalones/automations/actions/PushDataToFlexiblePowerAPI.vue";
import findRecords from "./components/standalones/automations/actions/findRecords.vue";
import updateRecords from "./components/standalones/automations/actions/updateRecords.vue";
import deleteRecords from "./components/standalones/automations/actions/deleteRecords.vue";
import exportMaintenanceRecordIntoExcelFile from "./components/standalones/automations/actions/exportMaintenanceRecordIntoExcelFile.vue";
import runJob from "./components/standalones/automations/actions/runJob.vue";
import createCollectionSheets from "./components/standalones/automations/actions/createCollectionSheets.vue";
import createFlexAnnualContracts from "./components/standalones/automations/actions/createFlexAnnualContracts.vue";
import UpdateFutureEnrAvailability from "./components/standalones/automations/actions/UpdateFutureEnrAvailability.vue";
import sendAvailabilityFilesToAutoGrid from "./components/standalones/automations/actions/sendAvailabilityFilesToAutoGrid.vue";
import checkFlexSitesReliability from "./components/standalones/automations/actions/checkFlexSitesReliability.vue";
import exportActivityLevelAndAvailabilityFromFlexSitesToFlexiblePowerAPIFormat from "./components/standalones/automations/actions/exportActivityLevelAndAvailabilityFromFlexSitesToFlexiblePowerAPIFormat.vue";
import Loader from "./components/Loader.vue";
import PopAction from "./components/pop_action/PopAction.vue";

Vue.component("project-show", ProjectShow);
Vue.component('table-profile-filters', tableProfileFilters);
Vue.component('delete-automation-module', deleteAutomationModule);
Vue.component('when-record-matches-conditions', whenRecordMatchesConditions);
Vue.component('when-record-created', whenRecordCreated);
Vue.component('when-record-updated', whenRecordUpdated);
Vue.component('when-record-destroyed', whenRecordDestroyed);
Vue.component('at-scheduled-time', atScheduledTime);
Vue.component('when-called', whenCalled);
Vue.component('send-notifications', sendNotifications);
Vue.component('export-record', exportRecord);
Vue.component('send-emails', sendEmails);
Vue.component('create-or-update-flex-unavailability', CreateOrUpdateFlexUnavailability);
Vue.component('push-data-to-flexible-power-api', PushDataToFlexiblePowerAPI);
Vue.component('find-records', findRecords);
Vue.component('update-records', updateRecords);
Vue.component('delete-records', deleteRecords);
Vue.component('run-job', runJob);
Vue.component('create-collection-sheets', createCollectionSheets);
Vue.component('create-flex-annual-contracts', createFlexAnnualContracts);
Vue.component('update-future-enr-availability', UpdateFutureEnrAvailability);
Vue.component('export-maintenance-record-into-excel-file', exportMaintenanceRecordIntoExcelFile);
Vue.component('send-availability-files-to-auto-grid', sendAvailabilityFilesToAutoGrid);
Vue.component('check-flex-sites-reliability', checkFlexSitesReliability);
Vue.component('export-activity-level-and-availability-from-flex-sites-to-flexible-power-api-format', exportActivityLevelAndAvailabilityFromFlexSitesToFlexiblePowerAPIFormat);
Vue.component("Loader", Loader);
Vue.component("PopAction", PopAction);
// ##########

// PLUGINS
import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
Vue.component("vue-dropzone", vue2Dropzone);

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
Vue.use(VueToast);

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/fr';
import 'vue2-datepicker/locale/es';
Vue.component('date-picker', DatePicker);

import VueGridLayout from 'vue-grid-layout';
Vue.component('grid-layout', VueGridLayout.GridLayout);
Vue.component('grid-item', VueGridLayout.GridItem);

import { ResizeObserver } from 'vue-resize';
import 'vue-resize/dist/vue-resize.css';
Vue.component('resize-observer', ResizeObserver);

import { errorHandler } from "@appsignal/vue";
import appsignal        from "../plugins/appsignal";
Vue.config.errorHandler = errorHandler(appsignal, Vue);

import VuetifyDraggableTreeview from 'vuetify-draggable-treeview'
Vue.component('VuetifyDraggableTreeview', VuetifyDraggableTreeview)
// ##########


Vue.filter("formatDate", (value, date_format) => {
  if (value) {
    if (date_format === 'with_time') {
      return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
    } else {
      return moment(new Date(value)).format("DD/MM/YYYY");
    }
  }
});
Vue.filter("truncate", (value, length) => {
  if (value) return truncate(value, { length });
});

const EventBus = new Vue();

const initVueApp = () => {
  const vueApp  = document.getElementById("vue-application");

  if (window.vueApp) {
    window.vueApp.$destroy();
  }

  if (vueApp) {
    window.vueApp = new Vue({
      i18n,
      store,
      el: "#vue-application",
      destroyed() {
        EventBus.$off(); // removes all event listeners on EventBus
      }
    });
  }
}

export { initVueApp, EventBus };
