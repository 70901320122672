import ChartController from "../../chart/controller";

export default class extends ChartController {
  static targets = ["legendContainer"];

  get options () {
    const options = this.optionsValue;

    options.options = {
      tooltips: {
        position: "average",
        mode: "index",
        xPadding: 8,
        yPadding: 8,
        xAlign: "center",
        intersect: false,
        backgroundColor: "white",
        bodyFontColor: "rgb(26, 32, 44)",
        bodyFontStyle: "bold",
        bodyFontSize: 12,
        bodySpacing: 8,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: "1",
        titleFontColor: "rgb(26, 32, 44)",
        titleFontStyle: "normal",
        displayColors: false,
        callbacks: {
          title: this.tooltipTitleCallback.bind(this),
          label: this.tooltipLabelCallback.bind(this)
        }
      },
      scales: {
        yAxes: this.yAxes(),
        xAxes: [{
          type: "time",
          time: {
            displayFormats: {
              hour:  "k:mm",
              day:   "DD/MM",
              month: "DD/MM"
            }
          },
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            maxTicksLimit: 21,
            major: {
              enabled: true,
              fontStyle: "bold",
            },
          },
          gridLines: {
            display: false,
          }
        }],
      },
      animation: { duration: 0 },
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
    }

    return options;
  }

  connect() {
    this.render();
    this.setChartLegend();
  }

  setChartLegend() {
    let chartLegendItemsHtml = this.chart.data.datasets.map((item, datasetIndex) => {
      let itemHidden = this.chart.getDatasetMeta(datasetIndex).hidden;
      let label = item.label;
      let labelColor = !itemHidden ? "#1A202C" : "#4A5568";
      let borderColor = item.borderColor || "#6B7280";
      let backgroundColor = !itemHidden ? (item.backgroundColor || "#6B7280") : "white";
      let checkColor = (!itemHidden && item.borderDash) ? item.borderColor : "white";
      let borderStyle = item.borderDash ? "dashed" : "solid";
      let opacity = !itemHidden ? "initial" : "0.4";
      let labelTextDecoration = !itemHidden ? "initial" : "line-through";

      return `
        <div class="tw-flex tw-items-center tw-cursor-pointer" data-action="click->dataset-chart#toggleSerie" data-dataset-chart-dataset-index-param=${datasetIndex}>
          <div class="tw-w-5 tw-h-5 tw-p-0 tw-flex tw-items-center tw-justify-center" style="background-color: ${backgroundColor}; border: 2px ${borderStyle} ${borderColor}; opacity: ${opacity};">
            <i class="fas fa-check" style="color: ${checkColor};"></i>
          </div>
          <div class="tw-text-sm tw-tracking-wide tw-opacity-80 tw-ml-3" style="color: ${labelColor}; text-decoration: ${labelTextDecoration}; opacity: ${opacity};">${label}</div>
        </div>
      `;
    });

    this.legendContainerTarget.innerHTML = chartLegendItemsHtml.join("");
  }

  tooltipTitleCallback(tooltipItems) {
    var date            = new Date(tooltipItems[0].label);
    var dateOptions     = { month: 'long', day: 'numeric' };
    var dateTimeOptions = { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};

    if (date.getHours() == 0 && date.getMinutes() == 0 && date.getSeconds() == 0) {
      return date.toLocaleDateString(this.localeValue, dateOptions);
    } else {
      return date.toLocaleDateString(this.localeValue, dateTimeOptions);
    }
  }

  tooltipLabelCallback(tooltipItem, data) {
    var unit = data.datasets[tooltipItem.datasetIndex].unit
    var label = data.datasets[tooltipItem.datasetIndex].label || '';
    var value = tooltipItem.yLabel;

    if(unit.includes('€') && !(value % 1 === 0)){
      value = Intl.NumberFormat(this.localeValue, { minimumFractionDigits: 2 }).format(value);
    } else {
      value = Intl.NumberFormat(this.localeValue).format(value);
    }

    if (label) {
      label += ' : ' + value + ' ' + unit;
    }

    return label;
  }

  toggleSerie(event) {
    const itemDatasetIndex = event.params.datasetIndex;
    let meta = this.chart.getDatasetMeta(itemDatasetIndex);

    // See controller.isDatasetVisible comment
    meta.hidden = meta.hidden === null ? !this.chart.data.datasets[itemDatasetIndex].hidden : null;

    this.chart.update();
    this.setChartLegend();
  }

  yAxes() {
    return this.yLabelStringArray().map((yLabel, index) => {
      return {
          offset: true,
          position: ['left', 'right'][index % 2],
          id: `y-axis-${yLabel}`,
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 8,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: yLabel,
            padding: 6,
            fontSize: 15,
            fontStyle: 'bold'
          }
        }
    })
  }

  yLabelStringArray() {
    if (Array.isArray(this.optionsValue.data.ylabelString)) {
      return this.optionsValue.data.ylabelString;
    }

    return Array.of(this.optionsValue.data.ylabelString);
  }
}
